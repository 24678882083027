import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import TabCardGrid from "components/cards/TabCardGrid.js";
import { motion } from "framer-motion";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import TextEditor from "./TextEditor";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { updateProduct, deleteProductByObject } from "services/productService";
import { useHistory } from "react-router-dom";
import Dialog from "./Dialog";
import { getTranslation, translateCategory, translateInformationTabs } from "translation/translationService";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const Container = tw.div`relative py-20 md:py-24 px-6`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto  items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  tw`max-w-[80%]`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = styled(SectionHeading)((props) => [
  `mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`,
  props.error && tw`text-red-700`,
]);
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const AdminToolsContainer = tw.div`flex w-full justify-between pr-8`;
const AdminButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0 border-primary-700 border-8`,
  props.buttonRounded && tw`rounded-full`,
  (props.disabled || props.disableStyles) && tw`bg-gray-100 text-primary-500`,
]);
const DeleteButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0 border-primary-700 border-8`,
  tw`rounded-full`,
  tw`bg-red-700`,
]);

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`,
  (props.disabled || props.disableStyles) && tw`bg-gray-100 text-primary-500`,
]);

const EditingButton = tw.textarea`px-8 py-3 font-bold rounded bg-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`;

export default ({ isAdmin, originalProduct, languageCode = "en" }) => {
  const testProduct = {
    "id": 860,
    "title": "  ASHWAGANDHA 60s",
    "body": "<p><strong>The ultimate adaptogen for stress, anxiety, mood focus and concentration.</strong></p><p>Symbio® Fermented Ashwagandha is shown to be more digestible and absorbable, with higher flavonoids and steroidal lactones, called withanolides.</p><p>- Effective in Reducing Stress<br>- Improves Anxiety &amp; Mood<br>- Helps Focus and Concentration</p>",
    "producttype": "",
    "price": "Rejuvenates & Reduces Stress",
    "productsize": "",
    "imagesrc": "https://comeforhealth-product-images.s3.us-east-2.amazonaws.com/Ashwagandha_Front_EN_768x832.jpg",
    "status": "",
    "category": "Sleep & Stress",
    "ingredients": "<h2><strong>Medicinal Ingredients</strong></h2><p>Symbio® Fermented, Organic Ashwagandha&nbsp;120mg</p><p><em>(Withania somnifera, root)</em></p><h2><strong>Support Herb Blend</strong></h2><p>Organic Ashwagandha&nbsp;120mg, <em>(Withania somnifera, root)</em></p><p>Organic Bacopa&nbsp;80mg, <em>(Bacopa monnieri, whole plant)</em></p><p>Organic Turmeric&nbsp;80mg, <em>(Curcuma longa, rhizome)</em></p><h2><strong>Non-medicinal Ingredients</strong></h2><p>Organic pullulan capsule (Organic pullulan starch, water)</p>",
    "suggesteduse": "<ul><li><p>Adults: Take 1-3 capsules daily with or without food.</p></li></ul>",
    "handle": "-ashwagandha-60s",
    "translated_body": "<p><strong>壓力、焦慮、情緒、專注力和注意力的終極適應原。</strong></p><p>Symbio® 發酵灵芝具有更好的消化和吸收能力，含有更高的類黃酮和類固醇內酯，稱為灵芝甾醇。</p><p>- 有效減輕壓力<br>- 改善焦慮和情緒<br>- 幫助專注和注意力</p>",
    "translated_suggesteduse": "",
    "translated_ingredients": "",
    "translations": {
        "chinese [traditional]": {
            "title": "阿斯瓦甘德哈60粒",
            "body": "<p><strong>壓力、焦慮、情緒、專注力和注意力的終極適應原。</strong></p><p>Symbio® 發酵灵芝具有更好的消化和吸收能力，含有更高的類黃酮和類固醇內酯，稱為灵芝甾醇。</p><p>- 有效減輕壓力<br>- 改善焦慮和情緒<br>- 幫助專注和注意力</p>",
            "suggesteduse": "",
            "ingredients": ""
        }
    }
    
  };
  const textOnLeft = true;
  const [activeTab, setActiveTab] = useState("Ingredients");
  const tabs = { Ingredients: "ingredients", "Suggested Use": "suggesteduse" }; // DisplayName : propertyname pairing
  const [product, setProduct] = useState(originalProduct || testProduct);
  const [needToSave, setNeedToSave] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const history = useHistory();

  const productDisplayTranslations = getTranslation(languageCode, "productDisplay");
  
  useEffect(() => setNeedToSave(true), [product]);
  
  useEffect(() => setProduct(originalProduct), [originalProduct]);

  function updateData() {
    updateProduct(product).then((response) => {
      if (response.handle && response.handle != product.handle) {
        history.push(`/product/${response.handle}`);
      } else if (response.error) {
        setErrorMessage("Error saving, please contact Administrator.");
      }
      setNeedToSave(false);
    });
  }

  // upload photo to
  function setImagePath(e) {
    const filePath = e.target.value;
    const fileName = filePath.split(/(\\|\/)/g).pop();

    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      setProduct({
        ...product,
        updatedImageBlob: reader.result,
        newFileName: fileName,
      });
    };
  }

  function setState(propertyName) {
    return (updatedData) => {
      setProduct({ ...product, [propertyName]: updatedData });
    };
  }

  function deleteProduct() {
    deleteProductByObject(product).then((response) => {
      if (response.message) {
        history.push(`/products`);
      } else if (response.error) {
        setErrorMessage("Error saving, please contact Administrator.");
      }
      setNeedToSave(false);
    });
  }

  function openDeleteProductWindow() {
    setIsDialogOpen(true);
  }

  return (
    <Container>
      {isAdmin ? (
        <AdminToolsContainer>
          <AdminButton
            cas="a"
            disableStyles={!editMode}
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? "Editing" : "Preview"}
          </AdminButton>
          <AdminButton cas="a" disabled={!needToSave} onClick={updateData}>
            {needToSave ? "Save" : "Saved"}
          </AdminButton>
        </AdminToolsContainer>
      ) : null}
      {errorMessage ? <Heading error={true}> {errorMessage} </Heading> : null}
      <TwoColumn>
        <ImageColumn css="display: flex; justify-content:center;flex-wrap: wrap; ">
          {editMode ? (
            <div>
              <Image src={product.imagesrc} />
              <input type="file" onChange={(e) => setImagePath(e)} />
            </div>
          ) : (
            <Image src={product.imagesrc} />
          )}
          {editMode ? (
            <EditingButton
              value={product.price}
              onChange={(e) =>
                setProduct({ ...product, price: e.target.value })
              }
              css="width:100%; text-align:center; "
            />
          ) : (
            product.price ? <Subheading css="width:100%; text-align:center;">
              {product.price}
            </Subheading> : null
          )}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{productDisplayTranslations.ourProduct}</Subheading>
            {editMode ? (
              <Heading>
                <textarea
                  value={product.title}
                  onChange={(e) =>
                    setProduct({ ...product, title: e.target.value })
                  }
                />
              </Heading>
            ) : (
              <Heading> {product.title}</Heading>
            )}
            <Description >
              <TextEditor
                className="text-display-container"
                html={product.body}
                setState={setState("body")}
                editMode={editMode}
              />
            </Description>
            {editMode ? (
              <EditingButton
                value={product.category}
                onChange={(e) =>
                  setProduct({ ...product, category: e.target.value })
                }
              />
            ) : (
              <PrimaryButton as="a">{translateCategory(product.category, languageCode)}</PrimaryButton>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>

      <HeaderRow>
        <TabsControl>
          {Object.keys(tabs).map((tabName, index) => (
            <TabControl
              key={index}
              active={activeTab === tabName}
              onClick={() => setActiveTab(tabName)}
            >
              {translateInformationTabs(tabName, languageCode)}
            </TabControl>
          ))}
        </TabsControl>
      </HeaderRow>
      {Object.keys(tabs).map((tabkey) => {
        return tabkey == activeTab ? (
          <div><TextEditor
            className="text-display-container"
            html={product[tabs[tabkey]]}
            setState={setState(tabs[tabkey])}
            editMode={editMode}
          />
          </div>
        ) : null;
      })}
      {isAdmin ? (
        <AdminToolsContainer>
          <DeleteButton cas="a" onClick={openDeleteProductWindow}>
            Delete
          </DeleteButton>
        </AdminToolsContainer>
      ) : null}
      {isAdmin ? (
        <Dialog
          isOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          deleteProduct={deleteProduct}
        />
      ) : null}
    </Container>
  );
};

//   "Description" : "A.Vogel Echinaforce® Extra: Can be taken by pregnant and breast feeding women Sugar- and gluten-free Echinacea purpurea is known for its antiviral, antibacterial and anti-inflammatory properties Organic cultivation certified by Bio Suisse
//   Take at the first sign of infection.
//   Efficacy clinically proven",
//   "Ingredients":
//   "Directions":
